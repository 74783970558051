import { blue } from "@material-ui/core/colors"

const Lol = () => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h1 style={{ color: "white" }}>Dont use java</h1>
    </div>
  )
}

export default Lol
