import React from "react"
import spinner from "../spinner.gif"

const Loading = () => {
  return (
    <div>
      <h1>Loading ...</h1>
      {/* <img
        src={spinner}
        style={{ width: "340px", margin: "auto", display: "block" }}
        alt="Loading..."
      /> */}
    </div>
  )
}

export default Loading
