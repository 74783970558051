import { Typography } from "@material-ui/core"
const About = () => {
  return (
    <div>
      <Typography color="primary" variant="h4">
        yoyo
      </Typography>
    </div>
  )
}

export default About
